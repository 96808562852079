/* eslint-disable max-lines */
import { getRuntimeEnv } from '@grille/utils/functions/get-runtime-env'
export const DEFAULT_CLOUDINARY_CLOUD_NAME = 'driveau'
export const CLOUDINARY_CLOUD_NAME =
  getRuntimeEnv('NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME') || DEFAULT_CLOUDINARY_CLOUD_NAME
export const ICON_CLOUDINARY_CLOUD_NAME = getRuntimeEnv('NEXT_PUBLIC_ICON_CLOUDINARY_CLOUD_NAME')
  ? getRuntimeEnv('NEXT_PUBLIC_ICON_CLOUDINARY_CLOUD_NAME')
  : 'driveau'
export const CLOUDINARY_HOST = getRuntimeEnv('NEXT_PUBLIC_IMAGE_HOSTNAME') ?? 'media.drive.com.au'
export const DRIVE_CLOUDINARY_URL = `https://${CLOUDINARY_HOST}`
export const DEFAULT_IMG_URL = `${DRIVE_CLOUDINARY_URL}/obj/${CLOUDINARY_CLOUD_NAME}/upload/cms/theme/drive-default-image`
export const DEFAULT_HERO_IMG_URL = `${DRIVE_CLOUDINARY_URL}/obj/${CLOUDINARY_CLOUD_NAME}/upload/cms/theme/default-hero-image`
export const DEFAULT_404_IMG_URL = `${DRIVE_CLOUDINARY_URL}/obj/tx_q:60,rs:fit:1920:1080:1/${CLOUDINARY_CLOUD_NAME}/upload/cms/theme/mustang-404`
export const DEFAULT_MAKE_URL = `${DRIVE_CLOUDINARY_URL}/obj/driveau/upload/vehicles/showrooms/makes/default-d.jpg`
export const DEFAULT_MAKE_CLOUDINARY = {
  cloudName: 'driveau',
  publicId: '/vehicles/showrooms/makes/default-d',
  deliveryType: ''
}
export const DEFAULT_AVATAR_URL = `${DRIVE_CLOUDINARY_URL}/obj/${CLOUDINARY_CLOUD_NAME}/upload/cms/theme/default-avatar`
export const CAR_FOR_SALE_DEFAULT_IMG_URL = `${DRIVE_CLOUDINARY_URL}/obj/${CLOUDINARY_CLOUD_NAME}/upload/cms/theme/coming_soon_image`
export const DEFAULT_CAR_SILHOUETTE_URL = `${DRIVE_CLOUDINARY_URL}/obj/${CLOUDINARY_CLOUD_NAME}/upload/cms/theme/car-silhouette`

//Breakpoint which will be mapped with declared reponsive sizes,
//congifs for srcset and src
export const IMAGES_BREAKPOINT = {
  HOMEPAGE_SHOWROOM_PROMO: [
    {
      width: 320,
      height: 180,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 306,
      fontSize: 16,
      useForSrc: true
    },
    {
      width: 640,
      height: 360,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 640,
      fontSize: 24,
      useForSrc: false
    },
    {
      width: 720,
      height: 405,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 720,
      fontSize: 24,
      useForSrc: false
    },
    {
      width: 828,
      height: 466,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 828,
      fontSize: 24,
      useForSrc: false
    },
    {
      width: 960,
      height: 540,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 960,
      fontSize: 24,
      useForSrc: false
    },
    {
      width: 1242,
      height: 699,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 1242,
      fontSize: 24,
      useForSrc: false
    }
  ],
  HOMEPAGE_CAROUSEL_V2: [
    {
      width: 640,
      height: 360,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 640,
      fontSize: 24,
      useForSrc: false
    },
    {
      width: 720,
      height: 405,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 720,
      fontSize: 24,
      useForSrc: false
    },
    {
      width: 828,
      height: 466,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 828,
      fontSize: 24,
      useForSrc: false
    },
    {
      width: 960,
      height: 540,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 960,
      fontSize: 24,
      useForSrc: false
    },
    {
      width: 1200,
      height: 675,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 1200,
      fontSize: 24,
      useForSrc: true,
      useForSrcset: true
    }
  ],
  ARCHIVE_CAROUSEL: [
    {
      width: 592,
      height: 333,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 592,
      fontSize: 50,
      useForSrc: true
    },
    {
      width: 720,
      height: 405,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 720,
      fontSize: 50
    },
    {
      width: 880,
      height: 495,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 880,
      fontSize: 50
    },
    {
      width: 1184,
      height: 666,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 1920,
      fontSize: 50
    }
  ],
  ARTICLE_CARD: [
    {
      // Desktop.
      width: 400,
      height: 225,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 400,
      fontSize: 20
    },
    {
      width: 640,
      height: 360,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 640,
      fontSize: 24
    },
    {
      width: 720,
      height: 405,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 720,
      fontSize: 24
    },
    {
      width: 880,
      height: 495,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 880,
      fontSize: 24
    },
    {
      width: 1280,
      height: 720,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 1280,
      fontSize: 24,
      useForSrc: true
    }
  ],
  ARTICLE_CARD_THUMBNAIL: [
    {
      // Desktop.
      width: 140,
      height: 96,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 140,
      fontSize: 8,
      useForSrc: true
    },
    {
      width: 280,
      height: 192,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 280,
      fontSize: 16
    },
    {
      width: 420,
      height: 286,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 420,
      fontSize: 24
    }
  ],
  ARTICLE_THREE_COL_LAYOUT: [
    {
      // Desktop.
      width: 176,
      height: 99,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 176,
      fontSize: 10,
      useForSrc: true
    },
    {
      width: 320,
      height: 180,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 280,
      fontSize: 12
    },
    {
      width: 480,
      height: 270,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 480,
      fontSize: 12
    }
  ],
  ARTICLE_CARD_NO_META_DARK: [
    {
      // Desktop.
      width: 400,
      height: 225,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 400,
      fontSize: 20,
      useForSrc: true
    },
    {
      width: 640,
      height: 360,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 640,
      fontSize: 24
    },
    {
      width: 720,
      height: 405,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 720,
      fontSize: 24
    },
    {
      width: 880,
      height: 495,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 880,
      fontSize: 24
    },
    {
      width: 1280,
      height: 720,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 1280,
      fontSize: 24
    }
  ],
  ARTICLE_CAROUSEL_INLINE: [
    {
      width: 640,
      height: 360,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 640,
      fontSize: 24,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 720,
      height: 405,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 720,
      fontSize: 24,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 828,
      height: 466,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 828,
      fontSize: 24,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 960,
      height: 540,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 960,
      fontSize: 24,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 1200,
      height: 675,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 1200,
      fontSize: 24,
      useForSrc: true,
      useForSrcset: true
    }
  ],
  REVIEW_HERO_GRID_A_B_FEATURED_IMAGE: [
    {
      width: 720,
      height: 405,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 720,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 960,
      height: 540,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 960,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 1280,
      height: 720,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 1280,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 1600,
      height: 900,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 1600,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 1920,
      height: 1080,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 1920,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 1920,
      height: 1080,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 1920,
      fontSize: 10,
      useForSrc: true,
      useForSrcset: false
    }
  ],
  REVIEW_HERO_GRID_A_B_SECONDARY_IMAGES: [
    {
      width: 400,
      height: 225,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 400,
      fontSize: 10,
      useForSrc: true,
      useForSrcset: true
    },
    {
      width: 720,
      height: 405,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 720,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    }
  ],
  REVIEW_HERO_COMPARISON_IMAGES: [
    {
      width: 720,
      height: 405,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 720,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 960,
      height: 540,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 960,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 1280,
      height: 720,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 1280,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 1600,
      height: 900,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 1600,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 1920,
      height: 1080,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 1920,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 1920,
      height: 1080,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 1920,
      fontSize: 10,
      useForSrc: true,
      useForSrcset: false
    }
  ],
  REVIEW_HERO_FULL_WIDTH: [
    {
      width: 720,
      height: 405,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 720,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 960,
      height: 540,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 960,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 1280,
      height: 720,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 1280,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 1600,
      height: 900,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 1600,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 1920,
      height: 1080,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 1920,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 1920,
      height: 1080,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 1920,
      fontSize: 10,
      useForSrc: true,
      useForSrcset: false
    }
  ],
  REVIEW_HERO_LEGACY_IMAGES: [
    {
      width: 720,
      height: 405,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 720,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 960,
      height: 540,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 960,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 1280,
      height: 720,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 1280,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 1600,
      height: 900,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 1600,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 1920,
      height: 1080,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 1920,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 1920,
      height: 1080,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 1920,
      fontSize: 10,
      useForSrc: true,
      useForSrcset: false
    }
  ],
  REVIEW_VIDEO_PLAYER: [
    {
      width: 640,
      height: 360,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 640,
      fontSize: 24,
      useForSrc: false
    },
    {
      width: 720,
      height: 405,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 720,
      fontSize: 24,
      useForSrc: false
    },
    {
      width: 828,
      height: 466,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 828,
      fontSize: 24,
      useForSrc: false
    },
    {
      width: 960,
      height: 540,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 960,
      fontSize: 24,
      useForSrc: false
    },
    {
      width: 1200,
      height: 675,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 1240,
      fontSize: 24,
      useForSrc: true,
      useForSrcset: true
    }
  ],
  LIGHTBOX_THUMBNAIL_VIEW: [
    {
      width: 256,
      height: 144,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      quality: 'auto:good',
      imgBreakpoint: 256,
      fontSize: 8,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 368,
      height: 207,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      quality: 'auto:good',
      imgBreakpoint: 368,
      fontSize: 8,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 448,
      height: 252,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      quality: 'auto:good',
      imgBreakpoint: 448,
      fontSize: 12,
      useForSrc: true,
      useForSrcset: true
    },
    {
      width: 544,
      height: 306,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      background: 'auto',
      quality: 'auto:eco',
      imgBreakpoint: 544,
      fontSize: 12,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 640,
      height: 360,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      quality: 'auto:eco',
      imgBreakpoint: 640,
      fontSize: 12,
      useForSrc: false,
      useForSrcset: true
    }
  ],
  CAR_FOR_SALE_CARD: [
    {
      // Desktop.
      width: 445,
      height: 225,
      fetchFormat: 'auto',
      crop: 'fit',
      quality: 'auto:best',
      imgBreakpoint: 445,
      fontSize: 20,
      useForSrc: true
    },
    {
      width: 640,
      height: 360,
      fetchFormat: 'auto',
      crop: 'fit',
      quality: 'auto:eco',
      imgBreakpoint: 640,
      fontSize: 24
    },
    {
      width: 720,
      height: 405,
      fetchFormat: 'auto',
      crop: 'fit',
      quality: 'auto:eco',
      imgBreakpoint: 720,
      fontSize: 24
    },
    {
      width: 828,
      height: 466,
      fetchFormat: 'auto',
      crop: 'fit',
      quality: 'auto:eco',
      imgBreakpoint: 828,
      fontSize: 24
    },
    {
      width: 890,
      height: 510,
      fetchFormat: 'auto',
      crop: 'fit',
      quality: 'auto:good',
      imgBreakpoint: 890,
      fontSize: 24
    },
    {
      width: 1242,
      height: 699,
      fetchFormat: 'auto',
      crop: 'fit',
      quality: 'auto:eco',
      imgBreakpoint: 1242,
      fontSize: 24
    },
    {
      width: 1335,
      height: 765,
      fetchFormat: 'auto',
      crop: 'fit',
      quality: 'auto:good',
      imgBreakpoint: 1335,
      fontSize: 24
    }
  ],
  CAR_FOR_SALE_CAROUSEL: [
    {
      width: 640,
      height: 360,
      fetchFormat: 'auto',
      crop: 'fit',
      quality: 'auto:eco',
      imgBreakpoint: 640,
      fontSize: 24
    },
    {
      width: 720,
      height: 405,
      fetchFormat: 'auto',
      crop: 'fit',
      quality: 'auto:eco',
      imgBreakpoint: 720,
      fontSize: 24
    },
    {
      width: 828,
      height: 466,
      fetchFormat: 'auto',
      crop: 'fit',
      quality: 'auto:eco',
      imgBreakpoint: 828,
      fontSize: 24
    },
    {
      // Desktop.
      width: 875,
      height: 490,
      fetchFormat: 'auto',
      crop: 'fit',
      quality: 'auto:best',
      imgBreakpoint: 875,
      fontSize: 24,
      useForSrc: true
    },
    {
      width: 1242,
      height: 699,
      fetchFormat: 'auto',
      crop: 'fit',
      quality: 'auto:eco',
      imgBreakpoint: 1242,
      fontSize: 24
    },
    {
      width: 1750,
      height: 980,
      fetchFormat: 'auto',
      crop: 'fit',
      quality: 'auto:good',
      imgBreakpoint: 1750,
      fontSize: 24
    }
  ],
  CARS_FOR_SALE_SECTION: [
    {
      // Desktop
      width: 202,
      height: 113,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 202,
      fontSize: 8,
      useForSrc: true
    },
    {
      width: 312,
      height: 226,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 312,
      fontSize: 12
    },
    {
      width: 404,
      height: 226,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 404,
      fontSize: 16
    },
    {
      width: 606,
      height: 339,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 606,
      fontSize: 16
    }
  ],
  THEME_IMAGE: [
    {
      // Desktop
      width: 202,
      height: 113,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 202,
      fontSize: 8,
      useForSrc: true
    },
    {
      width: 312,
      height: 226,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 312,
      fontSize: 12
    },
    {
      width: 404,
      height: 226,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 404,
      fontSize: 16
    },
    {
      width: 606,
      height: 339,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 606,
      fontSize: 16
    }
  ],
  PARTNERS_LOGO: [
    {
      width: 147,
      height: 44,
      fetchFormat: 'auto',
      crop: 'fit',
      quality: 'auto:eco',
      imgBreakpoint: 640,
      useForSrc: true,
      fontSize: 8
    }
  ],
  CONTENT_EXPLORER_BACKGROUND_IMAGE: [
    {
      width: 480,
      height: 270,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 400,
      fontSize: 14,
      useForSrc: true,
      useForSrcset: true
    },
    {
      width: 640,
      height: 360,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 640,
      fontSize: 18,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 1024,
      height: 576,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 1024,
      fontSize: 18,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 1280,
      height: 720,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 1280,
      fontSize: 24,
      useForSrc: false,
      useForSrcset: true
    }
  ],
  CONTENT_EXPLORER_LOGO_IMAGE: [
    {
      width: 48,
      height: 48,
      fetchFormat: 'auto',
      crop: 'fit',
      quality: 'auto:best',
      imgBreakpoint: 48,
      useForSrc: true,
      fontSize: 8
    },
    {
      width: 96,
      height: 96,
      fetchFormat: 'auto',
      crop: 'fit',
      quality: 'auto:eco',
      imgBreakpoint: 96,
      useForSrc: false,
      fontSize: 16
    },
    {
      width: 144,
      height: 144,
      fetchFormat: 'auto',
      crop: 'fit',
      quality: 'auto:eco',
      imgBreakpoint: 144,
      useForSrc: false,
      fontSize: 24
    }
  ],
  WHAT_MY_CAR_WORTH_CARD: [
    {
      width: 455,
      height: 256,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      background: 'auto',
      quality: 'auto:best',
      imgBreakpoint: 455,
      fontSize: 24,
      useForSrc: true
    },
    {
      width: 640,
      height: 360,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      background: 'auto',
      quality: 'auto:eco',
      imgBreakpoint: 640,
      fontSize: 14,
      useForSrc: false
    },
    {
      width: 720,
      height: 405,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      background: 'auto',
      quality: 'auto:eco',
      imgBreakpoint: 720,
      fontSize: 24
    },
    {
      width: 828,
      height: 466,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      background: 'auto',
      quality: 'auto:eco',
      imgBreakpoint: 828,
      fontSize: 24,
      useForSrc: false
    },
    {
      width: 910,
      height: 512,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      background: 'auto',
      quality: 'auto:eco',
      imgBreakpoint: 910,
      fontSize: 24,
      useForSrc: false
    },
    {
      width: 1242,
      height: 699,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      background: 'auto',
      quality: 'auto:eco',
      imgBreakpoint: 1242,
      fontSize: 24,
      useForSrc: false
    },
    {
      width: 1485,
      height: 768,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      background: 'auto',
      quality: 'auto:eco',
      imgBreakpoint: 1200,
      fontSize: 24,
      useForSrc: false
    }
  ],
  DRIVE_MARKETPLACELISTING_BLOCK_CARD: [
    {
      width: 320,
      height: 180,
      fetchFormat: 'auto',
      crop: 'auto',
      quality: 'auto:best',
      imgBreakpoint: 320,
      fontSize: 12,
      useForSrc: true,
      useForSrcset: true
    },
    {
      width: 640,
      height: 360,
      fetchFormat: 'auto',
      crop: 'auto',
      quality: 'auto:best',
      imgBreakpoint: 640,
      fontSize: 14,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 720,
      height: 405,
      fetchFormat: 'auto',
      crop: 'auto',
      quality: 'auto:good',
      imgBreakpoint: 720,
      fontSize: 16,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 1280,
      height: 720,
      fetchFormat: 'auto',
      crop: 'auto',
      quality: 'auto:good',
      imgBreakpoint: 1280,
      fontSize: 18,
      useForSrc: false,
      useForSrcset: true
    }
  ],
  DRIVE_MARKETPLACELISTING_BLOCK_CARD_COMPACT: [
    {
      width: 400,
      height: 225,
      fetchFormat: 'auto',
      crop: 'auto',
      quality: 'auto:best',
      imgBreakpoint: 400,
      fontSize: 12,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 640,
      height: 360,
      fetchFormat: 'auto',
      crop: 'auto',
      quality: 'auto:best',
      imgBreakpoint: 640,
      fontSize: 14,
      useForSrc: true,
      useForSrcset: true
    },
    {
      width: 720,
      height: 405,
      fetchFormat: 'auto',
      crop: 'auto',
      quality: 'auto:good',
      imgBreakpoint: 720,
      fontSize: 16,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 1280,
      height: 720,
      fetchFormat: 'auto',
      crop: 'auto',
      quality: 'auto:good',
      imgBreakpoint: 1280,
      fontSize: 18,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 1440,
      height: 810,
      fetchFormat: 'auto',
      crop: 'auto',
      quality: 'auto:eco',
      imgBreakpoint: 1440,
      fontSize: 24,
      useForSrc: false,
      useForSrcset: true
    }
  ],
  CARS_FOR_SALE_LISTINGS_CARD: [
    {
      width: 320,
      height: 180,
      fetchFormat: 'auto',
      crop: 'auto',
      quality: 'auto:best',
      imgBreakpoint: 320,
      fontSize: 12,
      useForSrc: true,
      useForSrcset: true
    },
    {
      width: 640,
      height: 360,
      fetchFormat: 'auto',
      crop: 'auto',
      quality: 'auto:best',
      imgBreakpoint: 640,
      fontSize: 14,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 720,
      height: 405,
      fetchFormat: 'auto',
      crop: 'auto',
      quality: 'auto:good',
      imgBreakpoint: 720,
      fontSize: 16,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 1280,
      height: 720,
      fetchFormat: 'auto',
      crop: 'auto',
      quality: 'auto:good',
      imgBreakpoint: 1280,
      fontSize: 18,
      useForSrc: false,
      useForSrcset: true
    }
  ],
  CARS_FOR_SALE_LISTING_SINGLE_CAROUSEL_SCHEMA: [
    {
      width: 1440,
      height: 810,
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 1440,
      fontSize: 12,
      useForSrc: true,
      useForSrcset: true
    }
  ],

  CARS_FOR_SALE_LISTING_SINGLE_CAROUSEL: [
    {
      width: 640,
      height: 360,
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 640,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 720,
      height: 405,
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 720,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 864,
      height: 486,
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 864,
      fontSize: 12,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 960,
      height: 540,
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 960,
      fontSize: 12,
      useForSrc: true,
      useForSrcset: true
    },
    {
      width: 1280,
      height: 720,
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 1280,
      fontSize: 12,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 1440,
      height: 810,
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 1440,
      fontSize: 24,
      useForSrc: false,
      useForSrcset: true
    }
  ],
  CARS_FOR_SALE_LISTING_SINGLE_CAROUSEL_SECONDARY: [
    {
      width: 128,
      height: 72,
      fetchFormat: 'auto',
      crop: 'fill_down',
      quality: 'auto:best',
      imgBreakpoint: 128,
      useForSrc: true,
      useForSrcset: true
    },
    {
      width: 240,
      height: 135,
      fetchFormat: 'auto',
      crop: 'fill_down',
      quality: 'auto:best',
      imgBreakpoint: 240,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 320,
      height: 180,
      fetchFormat: 'auto',
      crop: 'fill_down',
      quality: 'auto:good',
      imgBreakpoint: 320,
      useForSrc: false,
      useForSrcset: true
    }
  ],
  AUTHOR_AVATAR: [
    {
      width: 25,
      height: 25,
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 25,
      fontSize: 8,
      useForSrc: false
    },
    {
      width: 50,
      height: 50,
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 50,
      fontSize: 8,
      useForSrc: true
    },
    {
      width: 75,
      height: 75,
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 75,
      fontSize: 10,
      useForSrc: false
    }
  ],
  AUTHOR_AVATAR_META: [
    {
      width: 48,
      height: 48,
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 48,
      fontSize: 8,
      useForSrc: true
    },
    {
      width: 80,
      height: 80,
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 80,
      fontSize: 10,
      useForSrc: false
    },
    {
      width: 96,
      height: 96,
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 96,
      fontSize: 8,
      useForSrc: true
    }
  ],
  AUTHOR_ARCHIVE_CARD: [
    {
      width: 220,
      height: 220,
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 224,
      fontSize: 12,
      useForSrc: true
    },
    {
      width: 440,
      height: 440,
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 440,
      fontSize: 12,
      useForSrc: false
    }
  ],
  SHOWROOM_ARTICLE_CARD: [
    {
      width: 400,
      height: 225,
      fetchFormat: 'auto',
      crop: 'fill',
      gravity: 'auto',
      quality: 'auto:best',
      imgBreakpoint: 400,
      fontSize: 24,
      useForSrc: false
    },
    {
      width: 640,
      height: 360,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 640,
      fontSize: 24,
      useForSrc: false
    },
    {
      width: 720,
      height: 405,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 720,
      fontSize: 24,
      useForSrc: false
    },
    {
      width: 960,
      height: 540,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 960,
      fontSize: 24,
      useForSrc: false
    },
    {
      width: 1280,
      height: 720,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 1200,
      fontSize: 24,
      useForSrc: true
    }
  ],
  SHOWROOM_RIVAL_CARD: [
    {
      width: 400,
      height: 235,
      fetchFormat: 'auto',
      crop: 'auto',
      gravity: 'auto',
      background: 'auto',
      quality: 'auto:eco',
      imgBreakpoint: 400,
      fontSize: 24,
      useForSrc: false
    },
    {
      width: 640,
      height: 376,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      background: 'auto',
      quality: 'auto:eco',
      imgBreakpoint: 640,
      fontSize: 24,
      useForSrc: false
    },
    {
      width: 720,
      height: 424,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      background: 'auto',
      quality: 'auto:eco',
      imgBreakpoint: 720,
      fontSize: 24,
      useForSrc: false
    },
    {
      width: 960,
      height: 565,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      background: 'auto',
      quality: 'auto:eco',
      imgBreakpoint: 960,
      fontSize: 24,
      useForSrc: false
    },
    {
      width: 1200,
      height: 706,
      fetchFormat: 'auto',
      crop: 'auto',
      gravity: 'auto',
      background: 'auto',
      quality: 'auto:eco',
      imgBreakpoint: 1200,
      fontSize: 24,
      useForSrc: true
    }
  ],
  AUTHOR_SIDEBAR_COMPONENT: [
    {
      width: 60,
      height: 60,
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 60,
      fontSize: 8,
      useForSrc: true
    },
    {
      width: 120,
      height: 120,
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 120,
      fontSize: 10,
      useForSrc: false
    },
    {
      width: 180,
      height: 180,
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 180,
      fontSize: 8,
      useForSrc: false
    }
  ],
  AUTHOR_HOMEPAGE_LATEST_OPINION: [
    {
      width: 100,
      height: 100,
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 100,
      fontSize: 8,
      useForSrc: true
    },
    {
      width: 200,
      height: 200,
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 200,
      fontSize: 10,
      useForSrc: false
    },
    {
      width: 300,
      height: 300,
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 300,
      fontSize: 10,
      useForSrc: false
    }
  ],
  OUR_JOURNALISTS_AUTHOR_CARD: [
    {
      width: 100,
      height: 100,
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 100,
      fontSize: 8,
      useForSrc: true
    },
    {
      width: 200,
      height: 200,
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 200,
      fontSize: 10,
      useForSrc: false
    },
    {
      width: 300,
      height: 300,
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 300,
      fontSize: 10,
      useForSrc: false
    }
  ],
  SHOWROOM_MODEL_FEATURED: [
    {
      width: 640,
      height: 360,
      fetchFormat: 'auto',
      crop: 'auto',
      gravity: 'auto',
      background: 'auto',
      quality: 'auto:eco',
      imgBreakpoint: 640,
      fontSize: 24,
      useForSrc: false
    },
    {
      width: 720,
      height: 405,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      background: 'auto',
      quality: 'auto:eco',
      imgBreakpoint: 720,
      fontSize: 24,
      useForSrc: false
    },
    {
      width: 828,
      height: 466,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      background: 'auto',
      quality: 'auto:eco',
      imgBreakpoint: 828,
      fontSize: 24,
      useForSrc: false
    },
    {
      width: 792,
      height: 455,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      background: 'auto',
      quality: 'auto:best',
      imgBreakpoint: 792,
      fontSize: 24,
      useForSrc: true
    },
    {
      width: 1242,
      height: 699,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      background: 'auto',
      quality: 'auto:eco',
      imgBreakpoint: 1242,
      fontSize: 24,
      useForSrc: false
    },
    {
      width: 1480,
      height: 832,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      background: 'auto',
      quality: 'auto:good',
      imgBreakpoint: 1480,
      fontSize: 24,
      useForSrc: false
    }
  ],
  SHOWROOM_SEGMENT_CARD: [
    {
      width: 160,
      height: 90,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 160,
      fontSize: 8,
      useForSrc: false
    },
    {
      width: 240,
      height: 135,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 240,
      fontSize: 8,
      useForSrc: true
    },
    {
      width: 320,
      height: 180,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 320,
      fontSize: 10,
      useForSrc: false
    },
    {
      width: 480,
      height: 270,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 480,
      fontSize: 10,
      useForSrc: false
    }
  ],
  SHOWROOM_VARIANT_IMAGE: [
    {
      width: 320,
      height: 180,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      quality: 'auto:best',
      imgBreakpoint: 320,
      fontSize: 8
    },
    {
      width: 640,
      height: 360,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      quality: 'auto:good',
      imgBreakpoint: 640,
      fontSize: 8
    },
    {
      width: 960,
      height: 540,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      quality: 'auto:eco',
      imgBreakpoint: 960,
      fontSize: 8,
      useForSrc: true
    }
  ],
  MODEL_CARD_EXTENDED: [
    {
      width: 320,
      height: 178,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      background: 'auto',
      quality: 'auto:best',
      imgBreakpoint: 272,
      fontSize: 10,
      useForSrc: true
    },
    {
      width: 640,
      height: 356,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      background: 'auto',
      quality: 'auto:good',
      imgBreakpoint: 96,
      fontSize: 16
    },
    {
      width: 960,
      height: 534,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      background: 'auto',
      quality: 'auto:eco',
      imgBreakpoint: 144,
      fontSize: 24
    }
  ],
  CARS_FOR_SALE_PRICE_ONLY: [
    {
      width: 202,
      height: 113,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 202,
      fontSize: 8,
      useForSrc: true
    },
    {
      width: 312,
      height: 226,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 312,
      fontSize: 12
    },
    {
      width: 404,
      height: 226,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 404,
      fontSize: 16
    },
    {
      width: 606,
      height: 339,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 606,
      fontSize: 16
    }
  ],
  CAR_MATCHING_BLOCK: [
    {
      width: 271,
      height: 152,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      quality: 'auto:best',
      imgBreakpoint: 271,
      fontSize: 10,
      useForSrc: true
    },
    {
      width: 542,
      height: 304,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 542,
      fontSize: 12
    },
    {
      width: 813,
      height: 456,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 813,
      fontSize: 12
    }
  ],
  SHOWROOM_LANDING_FEATURED_CARD: [
    {
      width: 272,
      height: 155,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      background: 'auto',
      quality: 'auto:best',
      imgBreakpoint: 272,
      fontSize: 10,
      useForSrc: true
    },
    {
      width: 544,
      height: 310,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      background: 'auto',
      quality: 'auto:good',
      imgBreakpoint: 544,
      fontSize: 16,
      useForSrc: false
    },
    {
      width: 816,
      height: 465,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      background: 'auto',
      quality: 'auto:eco',
      imgBreakpoint: 816,
      fontSize: 22,
      useForSrc: false
    }
  ],
  MAKE_BADGE: [
    {
      width: 32,
      height: 32,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 32,
      fontSize: 8,
      useForSrc: true
    },
    {
      width: 64,
      height: 64,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 64,
      fontSize: 8,
      useForSrc: false
    },
    {
      width: 96,
      height: 96,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 96,
      fontSize: 8,
      useForSrc: false
    }
  ],
  SHOWROOM_MAKE_LANDING_DEALS_CARD: [
    {
      width: 272,
      height: 155,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      background: 'auto',
      quality: 'auto:best',
      imgBreakpoint: 272,
      fontSize: 8,
      useForSrc: true
    },
    {
      width: 544,
      height: 310,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      background: 'auto',
      quality: 'auto:good',
      imgBreakpoint: 64,
      fontSize: 16,
      useForSrc: false
    },
    {
      width: 816,
      height: 465,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      background: 'auto',
      quality: 'auto:eco',
      imgBreakpoint: 96,
      fontSize: 20,
      useForSrc: false
    }
  ],
  DEALS_CARD: [
    {
      width: 272,
      height: 155,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      background: 'auto',
      quality: 'auto:best',
      imgBreakpoint: 272,
      fontSize: 8,
      useForSrc: true
    },
    {
      width: 544,
      height: 310,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      background: 'auto',
      quality: 'auto:eco',
      imgBreakpoint: 544,
      fontSize: 16,
      useForSrc: false
    },
    {
      width: 816,
      height: 465,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      background: 'auto',
      quality: 'auto:eco',
      imgBreakpoint: 544,
      fontSize: 24,
      useForSrc: false
    }
  ],
  DEAL_CARD_MAKE_LOGO: [
    {
      width: 48,
      height: 48,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 48,
      fontSize: 8,
      useForSrc: true
    },
    {
      width: 96,
      height: 96,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 96,
      fontSize: 8,
      useForSrc: false
    },
    {
      width: 144,
      height: 144,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 144,
      fontSize: 8,
      useForSrc: false
    }
  ],
  SHOWROOM_ANNEX_CARDS: [
    {
      width: 140,
      height: 96,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      quality: 'auto:best',
      imgBreakpoint: 140,
      fontSize: 7,
      useForSrc: true
    },
    {
      width: 280,
      height: 192,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      quality: 'auto:good',
      imgBreakpoint: 280,
      fontSize: 10
    },
    {
      width: 420,
      height: 288,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      quality: 'auto:eco',
      imgBreakpoint: 420,
      fontSize: 14
    }
  ],
  SHOWROOM_ANNEX_CARDS_MAKE_LOGO: [
    {
      width: 32,
      height: 32,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 32,
      fontSize: 1,
      useForSrc: true
    },
    {
      width: 64,
      height: 64,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 96,
      fontSize: 1,
      useForSrc: false
    },
    {
      width: 96,
      height: 96,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 96,
      fontSize: 1,
      useForSrc: false
    }
  ],
  LATEST_EVS_MAKES: [
    {
      width: 72,
      height: 72,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 72,
      fontSize: 8,
      useForSrc: true
    },
    {
      width: 128,
      height: 128,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 128,
      fontSize: 8,
      useForSrc: false
    },
    {
      width: 144,
      height: 144,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 144,
      fontSize: 8,
      useForSrc: false
    },
    {
      width: 192,
      height: 192,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 192,
      fontSize: 8,
      useForSrc: false
    }
  ],
  MAKES_SHOWROOMS_LANDING_CARD: [
    {
      width: 72,
      height: 72,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 72,
      fontSize: 8,
      useForSrc: true
    },
    {
      width: 128,
      height: 128,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 128,
      fontSize: 8,
      useForSrc: false
    },
    {
      width: 144,
      height: 144,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 144,
      fontSize: 8,
      useForSrc: false
    },
    {
      width: 192,
      height: 192,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 192,
      fontSize: 8,
      useForSrc: false
    }
  ],
  SHOWROOM_MODEL_HERO_MEDIA: [
    {
      width: 720,
      height: 405,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 720,
      fontSize: 8,
      useForSrc: false
    },
    {
      width: 960,
      height: 540,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 960,
      fontSize: 8,
      useForSrc: false
    },
    {
      width: 1200,
      height: 675,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 1200,
      fontSize: 8,
      useForSrc: true
    },
    {
      width: 1280,
      height: 720,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 960,
      fontSize: 8,
      useForSrc: false
    },
    {
      width: 1600,
      height: 900,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 1600,
      fontSize: 8,
      useForSrc: false
    }
  ],
  SHOWROOM_HERO_MEDIA: [
    {
      width: 720,
      height: 405,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 720,
      fontSize: 12,
      useForSrc: false
    },
    {
      width: 1200,
      height: 675,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 1200,
      fontSize: 16,
      useForSrc: true
    },
    {
      width: 1440,
      height: 810,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 1440,
      fontSize: 24,
      useForSrc: false
    },
    {
      width: 1600,
      height: 900,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 1600,
      fontSize: 24,
      useForSrc: false
    }
  ],
  SHOWROOM_MODEL_CARD: [
    {
      width: 320,
      height: 180,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 320,
      fontSize: 8,
      useForSrc: true
    },
    {
      width: 640,
      height: 360,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 640,
      fontSize: 8,
      useForSrc: false
    },
    {
      width: 960,
      height: 540,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 960,
      fontSize: 10,
      useForSrc: false
    }
  ],
  SHOWROOM_IMAGE_GALLERY: [
    {
      width: 176,
      height: 99,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 176,
      fontSize: 8,
      useForSrc: true
    },
    {
      width: 352,
      height: 198,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 352,
      fontSize: 8,
      useForSrc: false
    },
    {
      width: 384,
      height: 216,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 384,
      fontSize: 8,
      useForSrc: false
    },
    {
      width: 768,
      height: 432,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 768,
      fontSize: 8,
      useForSrc: false
    }
  ],
  SHOWROOM_BADGE_MEDIA: [
    {
      width: 640,
      height: 360,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 640,
      fontSize: 8,
      useForSrc: true
    },
    {
      width: 720,
      height: 405,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 720,
      fontSize: 8,
      useForSrc: false
    },
    {
      width: 960,
      height: 540,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 960,
      fontSize: 8,
      useForSrc: false
    },
    {
      width: 1280,
      height: 720,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 1280,
      fontSize: 8,
      useForSrc: false
    }
  ],
  DCOTY_PARTNER_LOGO: [
    {
      width: 98,
      height: 54,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 98,
      fontSize: 6,
      useForSrc: true
    },
    {
      width: 196,
      height: 110,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 196,
      fontSize: 8
    },
    {
      width: 294,
      height: 162,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 294,
      fontSize: 12
    }
  ],
  DCOTY_LANDING_DESKTOP: [
    {
      width: 768,
      height: 432,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 768,
      fontSize: 10,
      useForSrc: false
    },
    {
      width: 1440,
      height: 688,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 1440,
      fontSize: 24,
      useForSrc: true
    }
  ],
  DCOTY_LANDING_MOBILE: [
    {
      width: 720,
      height: 800,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 720,
      fontSize: 24,
      useForSrc: true
    },
    {
      width: 1080,
      height: 1200,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 1080,
      fontSize: 24,
      useForSrc: false
    }
  ],
  DCOTY_HOMPAGE_CARDS: [
    {
      width: 320,
      height: 180,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 320,
      fontSize: 10,
      useForSrc: true
    },
    {
      width: 640,
      height: 360,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 640,
      fontSize: 16
    },
    {
      width: 960,
      height: 540,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 960,
      fontSize: 20
    }
  ],
  LIGHTBOX_GALLERY_VIEW: [
    {
      width: 720,
      height: 405,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 720,
      fontSize: 10,
      useForSrc: false
    },
    {
      width: 960,
      height: 540,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 960,
      fontSize: 10,
      useForSrc: false
    },
    {
      width: 1280,
      height: 720,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 1280,
      fontSize: 10,
      useForSrc: false
    },
    {
      width: 1600,
      height: 900,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 1600,
      fontSize: 10,
      useForSrc: false
    },
    {
      width: 1920,
      height: 1080,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 1920,
      fontSize: 10,
      useForSrc: true
    }
  ],
  LIGHTBOX_GALLERY_VIEW_2X: [
    {
      width: 1440,
      height: 810,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 720,
      fontSize: 10,
      useForSrc: false
    },
    {
      width: 1920,
      height: 1080,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 960,
      fontSize: 10,
      useForSrc: false
    },
    {
      width: 2560,
      height: 1440,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 1280,
      fontSize: 10,
      useForSrc: true
    },
    {
      width: 3200,
      height: 1800,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 1600,
      fontSize: 10,
      useForSrc: false
    },
    {
      width: 3840,
      height: 2160,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 1980,
      fontSize: 10,
      useForSrc: false
    }
  ],
  CARS_FOR_SALE_LISTING_SINGLE_GALLERY: [
    {
      width: 640,
      height: 360,
      fetchFormat: 'auto',
      crop: 'auto',
      quality: 'auto:best',
      imgBreakpoint: 640,
      fontSize: 14,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 1280,
      height: 720,
      fetchFormat: 'auto',
      crop: 'auto',
      quality: 'auto:good',
      imgBreakpoint: 1280,
      fontSize: 18,
      useForSrc: true,
      useForSrcset: true
    },
    {
      width: 1600,
      height: 900,
      fetchFormat: 'auto',
      crop: 'auto',
      quality: 'auto:eco',
      imgBreakpoint: 1600,
      fontSize: 24,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 2560,
      height: 1440,
      fetchFormat: 'auto',
      crop: 'auto',
      quality: 'auto:eco',
      imgBreakpoint: 2560,
      fontSize: 24,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 3200,
      height: 1800,
      fetchFormat: 'auto',
      crop: 'auto',
      quality: 'auto:eco',
      imgBreakpoint: 3200,
      fontSize: 24,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 3840,
      height: 2160,
      fetchFormat: 'auto',
      crop: 'auto',
      quality: 'auto:eco',
      imgBreakpoint: 3840,
      fontSize: 24,
      useForSrc: false,
      useForSrcset: true
    }
  ],
  CARS_FOR_SALE_LISTING_SINGLE_GALLERY_2X: [
    {
      width: 1280,
      height: 720,
      fetchFormat: 'auto',
      crop: 'auto',
      quality: 'auto:good',
      imgBreakpoint: 1280,
      fontSize: 24,
      useForSrc: true,
      useForSrcset: true
    },
    {
      width: 1600,
      height: 900,
      fetchFormat: 'auto',
      crop: 'auto',
      quality: 'auto:eco',
      imgBreakpoint: 1600,
      fontSize: 24,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 2560,
      height: 1440,
      fetchFormat: 'auto',
      crop: 'auto',
      quality: 'auto:eco',
      imgBreakpoint: 2560,
      fontSize: 24,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 2880,
      height: 1620,
      fetchFormat: 'auto',
      crop: 'auto',
      quality: 'auto:eco',
      imgBreakpoint: 2880,
      fontSize: 24,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 3840,
      height: 2160,
      fetchFormat: 'auto',
      crop: 'auto',
      quality: 'auto:eco',
      imgBreakpoint: 3840,
      fontSize: 24,
      useForSrc: false,
      useForSrcset: true
    }
  ],
  DEFAULT_OG_IMAGE: [
    {
      width: 1280,
      height: 720,
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      useForSrc: true,
      fontSize: 24,
      imgBreakpoint: 1280
    }
  ],
  STRATTON_FINANCE_GET_OFFER_PROMO: [
    {
      width: 96,
      height: 50,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 96,
      fontSize: 8,
      useForSrc: true
    },
    {
      width: 192,
      height: 100,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 192,
      fontSize: 10,
      useForSrc: false
    }
  ],
  COMPARE_SPECS_CARD: [
    {
      width: 256,
      height: 144,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      background: 'auto',
      quality: 'auto:good',
      imgBreakpoint: 300,
      fontSize: 8,
      useForSrc: true
    },
    {
      width: 352,
      height: 198,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      background: 'auto',
      quality: 'auto:good',
      imgBreakpoint: 400,
      fontSize: 16
    },
    {
      width: 544,
      height: 306,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      background: 'auto',
      quality: 'auto:good',
      imgBreakpoint: 600,
      fontSize: 24
    }
  ],
  STANDALONE_MAKE_LOGO: [
    {
      width: 100,
      height: 100,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      background: 'auto',
      quality: 'auto:best',
      imgBreakpoint: 100,
      fontSize: 2,
      useForSrc: true
    }
  ],
  DCOTY_HOMEPAGE_WINNER_CARD_DESKTOP: [
    {
      width: 1240,
      height: 520,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 1240,
      fontSize: 12,
      useForSrc: false
    }
  ],
  DCOTY_HOMEPAGE_WINNER_CARD_MOBILE: [
    {
      width: 720,
      height: 540,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 720,
      fontSize: 10,
      useForSrc: true
    },
    {
      width: 828,
      height: 621,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 828,
      fontSize: 10,
      useForSrc: false
    }
  ],
  TOP_5_LANDING_CARD: [
    {
      // Desktop.
      width: 640,
      height: 360,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 515,
      fontSize: 16,
      useForSrc: true
    },
    {
      width: 720,
      height: 405,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto',
      imgBreakpoint: 720,
      fontSize: 20
    },
    {
      width: 880,
      height: 495,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 880,
      fontSize: 20
    }
  ],
  FEATURED_VIDEO_MAIN: [
    {
      width: 720,
      height: 405,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 720,
      fontSize: 24,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 828,
      height: 466,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 828,
      fontSize: 24,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 960,
      height: 540,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 960,
      fontSize: 24,
      useForSrc: true,
      useForSrcset: true
    },
    {
      width: 1200,
      height: 674,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 1240,
      fontSize: 24,
      useForSrc: true,
      useForSrcset: true
    }
  ],
  FEATURED_VIDEO_THUMB: [
    {
      // Desktop.
      width: 140,
      height: 96,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 140,
      fontSize: 8,
      useForSrc: true,
      useForSrcset: true
    },
    {
      width: 280,
      height: 192,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 280,
      fontSize: 16,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 420,
      height: 286,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 420,
      fontSize: 24,
      useForSrc: false,
      useForSrcset: true
    }
  ],
  SHOWROOMS_FEATURED_VIDEO_THUMB: [
    {
      width: 208,
      height: 117,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 140,
      fontSize: 8,
      useForSrc: true,
      useForSrcset: true
    },
    {
      width: 208,
      height: 117,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 280,
      fontSize: 8,
      useForSrc: true,
      useForSrcset: true
    }
  ],
  SEARCH_CARD_THUMBNAIL: [
    {
      width: 140,
      height: 96,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 140,
      fontSize: 8,
      useForSrc: true,
      useForSrcset: true
    },
    {
      width: 280,
      height: 192,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto',
      imgBreakpoint: 280,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    }
  ],
  BP_PARTNER_LOGO: [
    {
      width: 43,
      height: 57,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 48,
      fontSize: 6,
      useForSrc: true
    }
  ],
  COMPARE_BUCKET_CARD: [
    {
      width: 160,
      height: 90,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      quality: 'auto:best',
      imgBreakpoint: 160,
      fontSize: 8
    },
    {
      width: 320,
      height: 180,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      quality: 'auto:good',
      imgBreakpoint: 320,
      fontSize: 10,
      useForSrc: true
    },
    {
      width: 480,
      height: 270,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      quality: 'auto:eco',
      imgBreakpoint: 480,
      fontSize: 10
    }
  ],
  CONTACT_SELLER_BAR: [
    {
      width: 138,
      height: 82,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      quality: 'auto:best',
      imgBreakpoint: 138,
      fontSize: 6,
      useForSrc: true
    }
  ],
  FEATURED_HERO_BLOCK_ICON: [
    {
      width: 80,
      height: 80,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 1024,
      fontSize: 6,
      useForSrc: true
    }
  ],
  SHOWROOM_BEST_CARS_LANDING_CARD: [
    {
      width: 400,
      height: 225,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 374,
      fontSize: 10,
      useForSrc: true
    },
    {
      width: 800,
      height: 450,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 800,
      fontSize: 12,
      useForSrc: false
    },
    {
      width: 1200,
      height: 675,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 1200,
      fontSize: 14,
      useForSrc: false
    }
  ],
  SHOWROOMS_LANDING_COMPARISON_IMAGE: [
    {
      width: 360,
      height: 278,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 360,
      fontSize: 8,
      useForSrc: true
    },
    {
      width: 400,
      height: 308,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 400,
      fontSize: 10,
      useForSrc: false
    },
    {
      width: 480,
      height: 370,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 480,
      fontSize: 10,
      useForSrc: false
    },
    {
      width: 720,
      height: 556,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 720,
      fontSize: 10,
      useForSrc: false
    },
    {
      width: 1200,
      height: 925,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 1200,
      fontSize: 10,
      useForSrc: false
    }
  ],
  SHOWROOMS_LANDING_HERO: [
    {
      width: 720,
      height: 405,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 720,
      fontSize: 10,
      useForSrc: false
    },
    {
      width: 960,
      height: 540,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 960,
      fontSize: 10,
      useForSrc: false
    },
    {
      width: 1200,
      height: 675,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 1200,
      fontSize: 10,
      useForSrc: true
    },
    {
      width: 1440,
      height: 810,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 1440,
      fontSize: 10,
      useForSrc: false
    },
    {
      width: 1600,
      height: 900,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 1600,
      fontSize: 10,
      useForSrc: false
    }
  ],
  SHOWROOMS_MAKE_RANGE: [
    {
      width: 288,
      height: 162,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 288,
      fontSize: 10,
      useForSrc: true
    },
    {
      width: 576,
      height: 324,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 576,
      fontSize: 10,
      useForSrc: false
    },
    {
      width: 864,
      height: 486,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 864,
      fontSize: 10,
      useForSrc: false
    }
  ],
  SHOWROOMS_LANDING_FEATURED_POST: [
    {
      width: 640,
      height: 360,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 640,
      fontSize: 10,
      useForSrc: false
    },
    {
      width: 720,
      height: 405,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 720,
      fontSize: 10,
      useForSrc: false
    },
    {
      width: 960,
      height: 540,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 960,
      fontSize: 10,
      useForSrc: false
    },
    {
      width: 1200,
      height: 675,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 1200,
      fontSize: 10,
      useForSrc: true
    },
    {
      width: 1920,
      height: 1080,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 1920,
      fontSize: 10,
      useForSrc: false
    }
  ],
  SHOWROOM_BEST_CARS_LIST_CARD: [
    {
      width: 320,
      height: 180,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 320,
      fontSize: 10,
      useForSrc: true
    },
    {
      width: 640,
      height: 360,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 640,
      fontSize: 12,
      useForSrc: false
    },
    {
      width: 960,
      height: 540,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 960,
      fontSize: 12,
      useForSrc: false
    }
  ],
  HOMEPAGE_WHATS_ON_AT_DRIVE_LARGE: [
    {
      width: 480,
      height: 270,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 480,
      fontSize: 10,
      useForSrc: false
    },
    {
      width: 640,
      height: 360,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 640,
      fontSize: 10,
      useForSrc: false
    },
    {
      width: 720,
      height: 405,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 720,
      fontSize: 10,
      useForSrc: false
    },
    {
      width: 960,
      height: 540,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 960,
      fontSize: 10,
      useForSrc: true
    }
  ],
  HOMEPAGE_WHATS_ON_AT_DRIVE_SMALL: [
    {
      width: 240,
      height: 135,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 240,
      fontSize: 10,
      useForSrc: false
    },
    {
      width: 320,
      height: 180,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 320,
      fontSize: 10,
      useForSrc: false
    },
    {
      width: 480,
      height: 270,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 480,
      fontSize: 10,
      useForSrc: true
    }
  ],
  HOMEPAGE_BEST_CARS: [
    {
      width: 144,
      height: 81,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 144,
      fontSize: 8,
      useForSrc: true
    },
    {
      width: 288,
      height: 162,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 288,
      fontSize: 10,
      useForSrc: false
    },
    {
      width: 432,
      height: 243,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 432,
      fontSize: 10,
      useForSrc: false
    }
  ],
  WP_BLOCK_CURATED_CARS: [
    {
      width: 144,
      height: 81,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 144,
      fontSize: 8,
      useForSrc: true
    },
    {
      width: 288,
      height: 162,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 288,
      fontSize: 10,
      useForSrc: false
    },
    {
      width: 432,
      height: 243,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 432,
      fontSize: 10,
      useForSrc: false
    }
  ],
  WP_BLOCK_CHARGING_CALCULATOR_VARIANT: [
    {
      width: 198,
      height: 111,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 198,
      fontSize: 8,
      useForSrc: true
    },
    {
      width: 396,
      height: 222,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 396,
      fontSize: 8,
      useForSrc: false
    },
    {
      width: 594,
      height: 333,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 594,
      fontSize: 8,
      useForSrc: false
    },
    {
      width: 442,
      height: 249,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 442,
      fontSize: 8,
      useForSrc: false
    },
    {
      width: 884,
      height: 498,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 884,
      fontSize: 8,
      useForSrc: false
    },
    {
      width: 1326,
      height: 747,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 1326,
      fontSize: 8,
      useForSrc: false
    }
  ],
  SHOWROOMS_TOP_MODELS: [
    {
      width: 240,
      height: 135,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 240,
      fontSize: 10,
      useForSrc: true
    },
    {
      width: 384,
      height: 216,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 384,
      fontSize: 10,
      useForSrc: false
    },
    {
      width: 512,
      height: 270,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 512,
      fontSize: 10,
      useForSrc: false
    }
  ],
  SHOWROOM_RELATED_MAKE_LOGO: [
    {
      width: 60,
      height: 60,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 60,
      fontSize: 1,
      useForSrc: false
    },
    {
      width: 78,
      height: 78,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 78,
      fontSize: 1,
      useForSrc: true
    },
    {
      width: 120,
      height: 120,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 120,
      fontSize: 1,
      useForSrc: false
    },
    {
      width: 180,
      height: 180,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 180,
      fontSize: 1,
      useForSrc: false
    }
  ],
  HOMEPAGE_CAR_ADVICE_CARD_THUMBNAIL: [
    {
      // Desktop.
      width: 176,
      height: 99,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 176,
      fontSize: 8,
      useForSrc: true
    },
    {
      width: 352,
      height: 198,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 352,
      fontSize: 16
    },
    {
      width: 528,
      height: 287,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 528,
      fontSize: 24
    }
  ],
  SHOWROOM_STRAP_CARD: [
    {
      width: 384,
      height: 200,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 384,
      fontSize: 24,
      useForSrc: true
    },
    {
      width: 768,
      height: 400,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 768,
      fontSize: 24
    },
    {
      width: 1152,
      height: 600,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 1152,
      fontSize: 24
    }
  ],
  VIDEO_SHORT_THUMBNAIL: [
    {
      // Desktop.
      width: 288,
      height: 512,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'crop',
      quality: 'auto:best',
      imgBreakpoint: 288,
      fontSize: 8,
      useForSrc: true
    },
    {
      width: 486,
      height: 864,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'crop',
      quality: 'auto:good',
      imgBreakpoint: 486,
      fontSize: 12
    }
  ],
  IMAGE_POST_SLIDER: [
    {
      width: 720,
      height: 405,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 720,
      fontSize: 24,
      useForSrc: false
    },
    {
      width: 960,
      height: 540,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 960,
      fontSize: 24,
      useForSrc: false
    }
  ],
  FEATURED_POST_BLOCK: [
    {
      width: 720,
      height: 405,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 720,
      fontSize: 24,
      useForSrc: false
    },
    {
      width: 960,
      height: 540,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 960,
      fontSize: 24,
      useForSrc: false
    },
    {
      width: 1280,
      height: 720,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 1280,
      fontSize: 24,
      useForSrc: true,
      useForSrcset: true
    }
  ],

  CARFINDER_SPOTLIGHT_COMPONENT: [
    {
      width: 600,
      height: 180,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 600,
      fontSize: 14,
      useForSrc: true,
      useForSrcset: true
    },
    {
      width: 900,
      height: 270,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 900,
      fontSize: 12,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 1200,
      height: 360,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 1200,
      fontSize: 18,
      useForSrc: false,
      useForSrcset: true
    }
  ],
  CARFINDER_BACKGROUND: [
    {
      width: 960,
      height: 540,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 960,
      fontSize: 14,
      useForSrc: true,
      useForSrcset: true
    },
    {
      width: 1200,
      height: 675,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 1200,
      fontSize: 14,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 1312,
      height: 738,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 1312,
      fontSize: 14,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 1440,
      height: 810,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 1440,
      fontSize: 14,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 1600,
      height: 900,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 1600,
      fontSize: 14,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 1920,
      height: 1080,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 1920,
      fontSize: 14,
      useForSrc: false,
      useForSrcset: true
    }
  ],

  MARKETPLACE_OEM_LOGO: [
    {
      width: 80,
      height: 80,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 80,
      fontSize: 8,
      useForSrc: true,
      useForSrcset: true
    },
    {
      width: 160,
      height: 160,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 160,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 240,
      height: 240,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 240,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    }
  ],
  MARKETPLACE_BODYTYPE_IMAGE: [
    {
      width: 75,
      height: 44,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 75,
      fontSize: 8,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 150,
      height: 88,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 150,
      fontSize: 9,
      useForSrc: true,
      useForSrcset: true
    },
    {
      width: 225,
      height: 132,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 225,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 300,
      height: 176,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 300,
      fontSize: 11,
      useForSrc: false,
      useForSrcset: true
    }
  ],
  FINANCE_CALC_PARTNER_LOGO: [
    {
      width: 272,
      height: 36,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      quality: 'auto:best',
      imgBreakpoint: 272
    },
    {
      width: 542,
      height: 72,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      quality: 'auto:good',
      imgBreakpoint: 542
    },
    {
      width: 814,
      height: 108,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'auto',
      quality: 'auto:good',
      imgBreakpoint: 814
    }
  ],
  SEARCH_MODELS: [
    {
      width: 124,
      height: 70,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 124,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 248,
      height: 140,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 248,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 372,
      height: 210,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 372,
      fontSize: 10,
      useForSrc: true,
      useForSrcset: true
    }
  ],
  SEARCH_ARTICLE_CARD: [
    {
      width: 156,
      height: 88,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 156,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 312,
      height: 176,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 312,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 468,
      height: 264,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 468,
      fontSize: 10,
      useForSrc: true,
      useForSrcset: true
    }
  ],
  SEARCH_ARTICLE_CARD_ALT: [
    {
      width: 122,
      height: 72,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 122,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 244,
      height: 144,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 244,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 366,
      height: 216,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 366,
      fontSize: 10,
      useForSrc: true,
      useForSrcset: true
    }
  ],
  HOMEPAGE_CAROUSEL_V3: [
    {
      width: 720,
      height: 405,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 720,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 960,
      height: 540,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 960,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 1280,
      height: 720,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 1280,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 1600,
      height: 900,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 1600,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 1920,
      height: 1080,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 1920,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 1920,
      height: 1080,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 1920,
      fontSize: 10,
      useForSrc: true,
      useForSrcset: false
    }
  ],
  CFS_HOMEPAGE_HERO: [
    {
      width: 640,
      height: 150,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 640,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 720,
      height: 169,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 720,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 960,
      height: 226,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 960,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 1280,
      height: 301,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 1280,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 1600,
      height: 376,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 1600,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 1920,
      height: 451,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 1920,
      fontSize: 10,
      useForSrc: true,
      useForSrcset: true
    }
  ],
  CFS_HOMEPAGE_NUSED_PROMO: [
    {
      width: 360,
      height: 247,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 360,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 720,
      height: 494,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 720,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 960,
      height: 658,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 960,
      fontSize: 10,
      useForSrc: true,
      useForSrcset: true
    },
    {
      width: 1080,
      height: 741,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 1080,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    }
  ],
  CFS_ABOUT_NUSED_LANDING_HERO: [
    {
      width: 720,
      height: 405,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 720,
      fontSize: 10,
      useForSrc: false
    },
    {
      width: 960,
      height: 540,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 960,
      fontSize: 10,
      useForSrc: false
    },
    {
      width: 1200,
      height: 675,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 1200,
      fontSize: 10,
      useForSrc: true
    },
    {
      width: 1440,
      height: 810,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 1440,
      fontSize: 10,
      useForSrc: false
    },
    {
      width: 1600,
      height: 900,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 1600,
      fontSize: 10,
      useForSrc: false
    }
  ],
  NUSED_BANNER_IMAGE: [
    {
      width: 482,
      height: 127,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 380,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 964,
      height: 254,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:good',
      imgBreakpoint: 760,
      fontSize: 10,
      useForSrc: false,
      useForSrcset: true
    },
    {
      width: 1446,
      height: 381,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:eco',
      imgBreakpoint: 1140,
      fontSize: 10,
      useForSrc: true,
      useForSrcset: true
    }
  ],
  NUSED_POPULAR: [
    {
      width: 280,
      height: 160,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 280,
      useForSrc: true,
      useForSrcset: true
    },
    {
      width: 448,
      height: 256,
      gravity: 'auto',
      fetchFormat: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      imgBreakpoint: 448,
      useForSrc: false,
      useForSrcset: true
    }
  ]
}

export const IMAGES_SIZES = {
  HOMEPAGE_CAROUSEL_V3: '(max-width: 1023px) 100vw, 751px',
  SEARCH_ARTICLE_CARD_ALT: '(max-width: 1023px) 96px, 122px',
  SEARCH_ARTICLE_CARD: '156px',
  SEARCH_MODELS: '124px',
  SHOWROOM_STRAP_CARD: `384px`,
  AUTHOR_AVATAR: '25px',
  AUTHOR_AVATAR_META: '48px',
  AUTHOR_SIDEBAR_COMPONENT: '60px',
  AUTHOR_HOMEPAGE_LATEST_OPINION: '100px',
  OUR_JOURNALISTS_AUTHOR_CARD: '100px',
  AUTHOR_ARCHIVE_CARD: '224px',
  ARCHIVE_CAROUSEL: '(max-width: 1023px) 100vw',
  ARTICLE_CARD: '(max-width: 768px) 100vw, 400px',
  ARTICLE_THREE_COL_LAYOUT: '160px',
  ARTICLE_CARD_THUMBNAIL: '140px',
  ARTICLE_CARD_NO_META_DARK: '(max-width: 1023px) 100vw, 400px',
  ARTICLE_CAROUSEL_FEATURED:
    '((max-width: 1023px) and (orientation: landscape)) calc((100vh - 112px) / 9 * 16), (max-width: 1023px) 100vw, ((max-width: 1180px) and (orientation: landscape)) calc((100vh - 202px) / 9 * 16), (max-width: 1920px) calc((100vh - 202px) / 9 * 16), 1920px',
  ARTICLE_CAROUSEL_INLINE:
    '((max-width: 1023px) and (orientation: landscape)) calc((100vh - 112px) / 9 * 16), (max-width: 1023px) 100vw, ((max-width: 1180px) and (orientation: landscape)) calc((100vh - 202px) / 9 * 16), (max-width: 1920px) calc((100vh - 202px) / 9 * 16), 1920px',
  HOMEPAGE_CAROUSEL_V2: '(max-width: 1023px) 100vw, (max-width: 1239px) 1024px, 944px',
  VIDEO_SHORT_THUMBNAIL: '(max-width: 440px) 180px, (max-width: 767px) 237px, 288px',
  CAR_MATCHING_BLOCK: ' 271px',
  IMAGE_POST_SLIDER:
    '(max-width: 767px) calc(100vw - 48px), (max-width: 1200px) calc((100vw - 64px)/2), 608px',
  CAR_FOR_SALE_CARD: '(max-width: 767px) 100vw, (max-width: 1023px) 50vw, 445px',
  FEATURED_POST_BLOCK:
    '(max-width: 767px) calc(100vw - 48px), (max-width: 1200px) calc(100vw - 64px), 1216px',
  CAR_FOR_SALE_CAROUSEL: '(max-width: 1023px) 100vw, 875px',
  CARS_FOR_SALE_SECTION: '(max-width: 320px) 312px, 202px',
  COMPARE_BUCKET_CARD: '160px',
  WHAT_MY_CAR_WORTH_CARD: '(max-width: 1023px) calc(100vw - 40px), 455px',
  SHOWROOM_MODEL_FEATURED: '(max-width: 767px) 100vw, 792px',
  CARS_FOR_SALE_LISTING_SINGLE_CAROUSEL_SCHEMA: '1280px',
  CARS_FOR_SALE_LISTING_SINGLE_CAROUSEL:
    '(max-width: 767px) 100vw, (max-width: 1023px) calc(100vw - 40px), (max-width: 1239px) 680px, 854px',
  CARS_FOR_SALE_LISTING_SINGLE_CAROUSEL_SECONDARY:
    '(max-width: 1023px) calc(100vw / 6), (max-width: 1239px) 100px, 125px',
  CARS_FOR_SALE_LISTINGS_CARD:
    '(max-width: 767px) calc(100vw - 3rem), (max-width: 1239px) calc(50vw - 2.5rem - 12px), 320px',
  DRIVE_MARKETPLACELISTING_BLOCK_CARD:
    '(max-width: 767px) calc(100vw - 3rem), (max-width: 1023px) 33vw, (max-width: 1239px) 25vw, 320px',
  DRIVE_MARKETPLACELISTING_BLOCK_CARD_COMPACT:
    '(max-width: 767px) 100vw, (max-width: 1023px) 50vw, 360px',
  SHOWROOM_SEGMENT_CARD: '(max-width: 767px) calc(50vw - 56px), 240px',
  SHOWROOMS_LANDING_COMPARISON_IMAGE: '(max-width: 414px) calc(100vw - 40px), 374px',
  SHOWROOMS_LANDING_HERO: '100vw',
  SHOWROOMS_LANDING_FEATURED_POST: '(max-width: 1023px) 100vw, 1980px',
  STRATTON_FINANCE_GET_OFFER_PROMO: '96px',
  SHOWROOMS_TOP_MODELS: '(max-width: 414px) 190px, (max-width: 767px) 210px, 230px',
  SHOWROOMS_MAKE_RANGE: '288px',
  THEME_IMAGE: '(max-width: 320px) 312px, 202px',
  MAKES_SHOWROOMS_LANDING_CARD: '(max-width: 979px) 64px, 72px',
  LATEST_EVS_MAKES: '(max-width: 979px) 64px, 72px',
  REVIEW_HERO_GRID_A_B_FEATURED_IMAGE: '(max-width: 1023px) 100vw, 704px',
  REVIEW_HERO_GRID_A_B_SECONDARY_IMAGES: '360px',
  REVIEW_HERO_FULL_WIDTH: '100vw',
  REVIEW_HERO_COMPARISON_IMAGES:
    '(min-width: 1280px) 604px, (min-width: 1024px) calc((100vw - 80px) / 2), 100vw',
  REVIEW_HERO_LEGACY_IMAGES: '(max-width: 1259px) 100vw, 1216px',
  REVIEW_VIDEO_PLAYER: '(max-width: 414px) 100vw, (max-width: 768px) calc(100vw-30px), 960px',
  SHOWROOM_ARTICLE_CARD: '290px',
  SHOWROOM_RIVAL_CARD: '290px',
  SHOWROOM_MAKE_CARD: '(max-width: 767px) calc(33vw - 60px), 80px',
  SHOWROOM_MODEL_CARD:
    '(max-width: 767px) calc(100vw - 2.5rem), (max-width: 979px) calc(50vw - 3.25rem), 298px',
  SHOWROOM_IMAGE_GALLERY: '(max-width: 676px) 100vw, 768px',
  SHOWROOM_HERO_MEDIA: '(max-width: 979px) 100vw, 60vw',
  SHOWROOM_BADGE_MEDIA: '(max-width: 768px) 100vw, 640px',
  SHOWROOM_MODEL_HERO_MEDIA:
    '(max-width: 979px) 100vw, (max-width: 1099px) 57vw, (max-width: 1440px) 65vw, 936px',
  SHOWROOM_BADGE_MEDIA: '(max-width: 979px) 100vw, 768px',
  MODEL_CARD_EXTENDED: '320px',
  SHOWROOM_ANNEX_CARDS: '320px',
  SHOWROOM_ANNEX_CARDS_MAKE_LOGO: '32px',
  SHOWROOM_RELATED_MAKE_LOGO: '(max-width: 979px) 60px, 78px',
  DEALS_CARD: '272px',
  DEAL_CARD_MAKE_LOGO: '48px',
  SHOWROOM_MAKE_LANDING_DEALS_CARD: '272px',
  MAKE_BADGE: '32px',
  SHOWROOM_LANDING_FEATURED_CARD: '272px',
  SHOWROOM_BEST_CARS_LANDING_CARD:
    '(max-width: 767px) calc(100vw - 2.5rem), (max-width: 979px) 292px, 374px',
  CARS_FOR_SALE_PRICE_ONLY: '(max-width: 320px) 312px, 202px',
  HOMEPAGE_SHOWROOM_PROMO: '(max-width: 767px) calc(100vw - 40px), 306px',
  DCOTY_PARTNER_LOGO: '98px',
  DCOTY_LANDING_DESKTOP: '(max-width: 768px) 100vw, 1440px',
  DCOTY_LANDING_MOBILE: '100vw',
  DCOTY_HOMPAGE_CARDS: '320px',
  LIGHTBOX_GALLERY_VIEW:
    '(max-width: 430px) 100vw, ((max-width: 932px) and (orientation: landscape)) calc((100vh - 120px) / 9 * 16), (max-width: 1920px) calc((100vh - 120px) / 9 * 16), 1920px',
  LIGHTBOX_GALLERY_VIEW_2X:
    '(max-width: 430px) 200vw, ((max-width: 932px) and (orientation: landscape)) calc((100vh - 120px) / 9 * 16), (max-width: 1920px) calc((100vh - 120px) / 9 * 16), 3640px',
  CARS_FOR_SALE_LISTING_SINGLE_GALLERY:
    '(max-width: 1023px) calc(100vw - 2.5rem), (max-width: 1599px) calc(100vw - 5.375rem - 338px), 1600px',
  CARS_FOR_SALE_LISTING_SINGLE_GALLERY_2X:
    '(max-width: 1023px) calc(2 * (100vw - 2.5rem)), (max-width: 1599px) calc(2 * (100vw - 5.375rem - 338px)), 3200px',
  LIGHTBOX_THUMBNAIL_VIEW:
    '(max-width: 460px) calc(50vw - 28px), (max-width: 1023px) calc(33vw - 28px), calc(25vw - 28px)',
  COMPARE_SPECS_CARD: '(max-width: 414px) 256px, 350px',
  STANDALONE_MAKE_LOGO: '100px',
  DCOTY_HOMEPAGE_WINNER_CARD_DESKTOP: '1240px',
  DCOTY_HOMEPAGE_WINNER_CARD_MOBILE: 'calc(100vw - 40px)',
  TOP_5_LANDING_CARD: '(max-width: 767px) calc(100vw - 40px), 515px',
  FEATURED_VIDEO_MAIN: '(max-width: 767px) calc(100vw - 40px), (max-width: 1239px) 720px, 960px',
  FEATURED_VIDEO_THUMB: '(max-width: 767px) calc(50vw - 20px), 130px',
  SEARCH_CARD_THUMBNAIL: '140px',
  BP_PARTNER_LOGO: '43px',
  CONTACT_SELLER_BAR: '138px',
  FEATURED_HERO_BLOCK_ICON: '80px',
  SHOWROOM_VARIANT_IMAGE: '320px',
  SHOWROOM_BEST_CARS_LIST_CARD:
    '(max-width: 767px) calc(100vw - 2.5rem), (max-width: 979px) 280px, 288px',
  HOMEPAGE_WHATS_ON_AT_DRIVE_LARGE: '(max-width: 767px) calc(100vw - 40px), 473px',
  HOMEPAGE_WHATS_ON_AT_DRIVE_SMALL:
    '(max-width: 767px) calc(50vw - 25px), (max-width: 1023px) 240px, 291px',
  HOMEPAGE_BEST_CARS: '144px',
  WP_BLOCK_CURATED_CARS: '144px',
  WP_BLOCK_CHARGING_CALCULATOR_VARIANT:
    '(max-width: 414px) 198px, (max-width: 767px) calc((100vw - 120px) * 70%), 198px',
  HOMEPAGE_CAR_ADVICE_CARD_THUMBNAIL: '(max-width: 979px) 150px, 176px',
  MARKETPLACE_OEM_LOGO: '61px',
  CONTENT_EXPLORER_BACKGROUND_IMAGE:
    '(max-width: 767px) calc(100vw - 28px), (max-width: 1023px) calc((100vw - 96px) / 3), 400px',
  CARFINDER_SPOTLIGHT_COMPONENT:
    '(max-width: 440px) calc(100vw - 80px), (max-width: 767px) calc(100vw - 294px), (max-width: 1023px) 496px, (max-width: 1440px) 55vw, 720px',
  CARFINDER_BACKGROUND: '(max-width: 1440px) 100vw, 1440px',
  MARKETPLACE_BODYTYPE_IMAGE: '75px',
  FINANCE_CALC_PARTNER_LOGO: '272px',
  CFS_HOMEPAGE_HERO: '(max-width: 767px) 100vw, 596px',
  CFS_HOMEPAGE_NUSED_PROMO: '(max-width: 639px) calc(100vw - 2.5rem), 408px',
  CFS_ABOUT_NUSED_LANDING_HERO: '100vw',
  NUSED_BANNER_IMAGE: '(max-width: 768px) 380px, (max-width: 1024px) 410px, 482px',
  NUSED_POPULAR: '(max-width: 767px) 140px, 224px'
}

export const IMAGES_FOLDERS = {
  ARCHIVE_CAROUSEL: 'cms/uploads/',
  ARTICLE_CARD: 'cms/uploads/',
  ARTICLE_CARD_THUMBNAIL: 'cms/uploads/',
  ARTICLE_CARD_NO_META_DARK: 'cms/uploads/',
  CAR_FOR_SALE_CARD: 'vehicles/used/',
  CAR_FOR_SALE_CAROUSEL: 'vehicles/used/',
  CARS_FOR_SALE_SECTION: 'vehicles/used/',
  THEME_IMAGE: 'cms/theme/',
  DEFAULT_IMAGE: 'cms/theme/',
  BP_PARTNER_LOGO: 'cms/theme/',
  DEFAULT_FOLDER_PATH: 'cms/uploads/',
  SHOWROOM_ARTICLE_CARD: 'cms/uploads/',
  SHOWROOM_RIVAL_CARD: 'cms/uploads/',
  REVIEW_VIDEO_PLAYER: 'cms/uploads/'
}
